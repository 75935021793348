import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
const Login = () => {
    const navigate=useNavigate()
    const [data,setData]=useState({
        email:'',
        password:""
    })
    const login=()=>{
        axios.post('https://spira-nature.com/api/admin/login',data)
        .then(r=>{
          localStorage.setItem('isSpira',"true1")
          navigate('/spiraNature/admin/dashboard')}).catch(err=>alert('wrong credentials'))
    }
    const handleInChange=(e)=>{
        const {name,value}=e.target
        setData((prev)=>{
            return {
                ...prev,
                [name]:value
            }
        })
    }
  return (
    <div className='w-fit h-fit m-auto mt-[300px] '>
      <div className='flex gap-[25px] flex-col my-auto  justify-center items-center'>
        <input type="email"
        name='email'
        placeholder='email'
        className='w-[250px] h-[45px] border-black p-4 font-semibold'
        onChange={(e)=>handleInChange(e)} />
        <input type="password"
        name='password'
        placeholder='password'
        className='w-[250px] h-[45px] border-black p-4 font-semibold '
        onChange={(e)=>handleInChange(e)} />
        <div
        className='bg-black text-white w-[150px] h-[40px] cursor-pointer flex justify-center items-center'
        onClick={()=>login()}
        >login</div>
        </div>
    </div>
  )
}

export default Login