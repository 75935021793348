import React, { useState, useEffect } from 'react';
import { TfiArrowCircleRight } from "react-icons/tfi";
import './brands.css';
import { useNavigate } from 'react-router-dom';

function Brands() {
  const [counter, setCounter] = useState(0);
  const [brands, setBrands] = useState([]);
  const [error, setError] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    try {
      const response = await fetch('https://spira-nature.com/api/brand/get');
      if (!response.ok) {
        throw new Error('Failed to fetch brands');
      }
      const data = await response.json();
      setBrands(data);
    } catch (error) {
      setError(error.message);
    }
  };
  const navigate=useNavigate()
  const handlefilter=(name)=>{
    navigate('/display/?query='+name)
  }
  const handleCounter = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setCounter((prevCounter) => (prevCounter + 5 >= brands.length ? 0 : prevCounter + 1));
      setIsAnimating(false);
    }, 300); // Duration of the fade-out animation
  };

  return (
    <div className="p-8">
      <div className="flex flex-row justify-between items-center">
        <div className='flex flex-row items-center gap-2'>
          <h2 className="text-7xl text-[#0072a4] md:ml-16 font-brownhill font-bold">Nos marques</h2>
        </div>
        <TfiArrowCircleRight className="text-[30px] text-[#fef9f3]" onClick={handleCounter} />
      </div>      
      <div className="flex flex-col items-center">
        <div className="mt-[30px] w-full">
          <div className="flex gap-[10px] p-4 md:flex md:overflow-x-visible md:gap-[120px] md:justify-center bg-[#fef9f3]">
            {brands.slice(counter, counter + 5).map((brand, index) => (
              <div key={index} className={`image-container ${isAnimating ? 'fade-out' : 'fade-in'}`}>
                <img 
                onClick={()=>handlefilter(brand.name)}
                className="w-[50px] h-[50px] object-cover md:w-[130px] md:h-[130px] md:object-cover" src={brand.image} alt={`Brand ${index}`} />
              </div>
            ))}
          </div>
        </div>
        {error && <p>{error}</p>}
      </div>
    </div>
  );
}

export default Brands;
