import React from 'react'
import { FaInstagram } from "react-icons/fa";
import { AiOutlineFacebook } from "react-icons/ai";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div className='w-full'>
      <div className='flex flex-col md:flex-row justify-center items-center gap-8 md:gap-16 p-6 w-full'>
        <div className='flex flex-col items-center text-center md:items-start md:text-left'>
          <p className='max-w-md'>Spira Nature, la première boutique spécialisée dans la vente de la spiruline. Pour la premiere fois une gamme dermo cosmétique à base de Spiruline.</p>
          <div className='flex flex-row gap-4 text-[19px] font-semibold mt-6'>
            <h1>Sociale</h1>
            <a href="https://www.facebook.com/spiranature" target="_blank" rel="noopener noreferrer" className="icon facebook-icon">
              <AiOutlineFacebook size={34} />
            </a>
            <a href="https://www.instagram.com/spira.nature/" target="_blank" rel="noopener noreferrer" className="icon instagram-icon">
              <FaInstagram size={34} />
            </a>
          </div>
        </div>

        <img src="/spiranature.png" alt="" className='w-1/2 md:w-1/4' />

        <div className='flex flex-col items-center md:items-start gap-2'>
          <h3 className='text-[20px] font-semibold'>À propos et services</h3>
          <Link to={'/about'}>
          <h2 className='cursor-pointer hover:underline'>À propos</h2></Link>
          <Link to={'/contact'}>
          <h2 className='cursor-pointer hover:underline'>Contactez-nous</h2></Link>
          <Link to={'/shop'}>
          <h2 className='cursor-pointer hover:underline'>Boutique</h2></Link>
          <Link to={'/FAQ'}>
          <h2 className='cursor-pointer hover:underline'>FAQs</h2></Link>
          <Link to={'/allblogs'}>
          <h2 className='cursor-pointer hover:underline'>Blogs</h2></Link>
        </div>
      </div>
      <div className='bg-black text-white flex flex-col md:flex-row justify-center items-center text-sm h-auto md:h-[40px] p-4 md:p-0'>
        <div><h1>All rights reserved 2024</h1></div>
        <div className='flex gap-4 mt-4 md:mt-0 cursor-pointer'>
          <h1>Privacy Choices</h1>
          <h1>Terms & Conditions</h1>
          <h1>Privacy Policy</h1>
        </div>
      </div>
    </div>
  )
}

export default Footer;
