import React, { useState } from 'react';
import Details from './Details';
import { RiDashboard3Line } from "react-icons/ri";
import { AiOutlineProduct } from "react-icons/ai";
import { HiOutlineUsers } from "react-icons/hi2";
import { CiSearch } from "react-icons/ci";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoIosArrowDropdown } from "react-icons/io";
import { BsBoxSeam } from "react-icons/bs";
import { TbBrandBlogger } from "react-icons/tb";
import { IoMdLogOut } from "react-icons/io";
import { MdOutlineBrandingWatermark } from "react-icons/md";
import Product from './Product';
import Clients from './Clients';
import Orders from './Orders';
import Dash from './Dash';
import Blog from './Blogs';
import Brands from './Brands';
import ContactComponent from './ContactAdmin';
import { useNavigate } from 'react-router-dom';
function DashNav() {
  const [ view,setView]=useState('dashboard')
  const navigate=useNavigate()
    const [clicked,setclicked]=useState([true,false,false,false,false,false])
  const manageView=()=>{
    if (view==="dashboard"){
      return <Dash/>
  }else  if (view==="products"){
      return <Product/>
  }else  if (view==="clients"){
      return <Clients/>
  }else  if (view==="blogs"){
    return <Blog/>
  }else  if (view==="brands"){
  return <Brands/>
}else  if (view==="contact"){
  return <ContactComponent/>
}
}
  return (
    <div className='flex flex-row h-full'>
    <div className='flex flex-col items-center gap-2 bg-white w-[20%] h-screen p-2 fixed'>
      <div className='flex justify-center'>
        <img src="\spiranature.png" className='w-[150px]' alt="Logo" />
      </div>
      <div className='flex flex-col gap-8'>
        <div className={clicked[0]?"flex flex-row items-center gap-4 bg-blue-500 text-white p-2 border rounded-lg shadow":"flex flex-row items-center gap-4 hover:text-blue-500 hover:cursor-pointer"} onClick={()=>{setView('dashboard') ;setclicked([true,false,false,false,false,false])}}>
          <RiDashboard3Line className='text-2xl w-8' />
          <span className='text-xl'>Tableau de bord</span>
        </div>
        <div className={clicked[1]?"flex flex-row items-center gap-4 bg-blue-500 text-white p-2 border rounded-lg shadow":"flex flex-row items-center gap-4 hover:text-blue-500 hover:cursor-pointer"} onClick={()=>{setView('products');setclicked([false,true,false,false,false,false])}}>
          <AiOutlineProduct className='text-2xl w-8' />
          <span className='text-xl'>Produits</span>
        </div>
        <div className={clicked[2]?"flex flex-row items-center gap-4 bg-blue-500 text-white p-2 border rounded-lg shadow":"flex flex-row items-center gap-4 hover:text-blue-500 hover:cursor-pointer"} onClick={()=>{setView('clients');setclicked([false,false,true,false,false,false])}}>
          <HiOutlineUsers className='text-2xl w-8' />
          <span className='text-xl'>Clients</span>
        </div>
        <div className={clicked[3]?"flex flex-row items-center gap-4 bg-blue-500 text-white p-2 border rounded-lg shadow":"flex flex-row items-center gap-4 hover:text-blue-500 hover:cursor-pointer"} onClick={()=>{setView('blogs');setclicked([false,false,false,true,false,false])}}>
          <TbBrandBlogger   className='text-2xl w-8' />
          <span className='text-xl'>Blog</span>
        </div>
        <div className={clicked[4]?"flex flex-row items-center gap-4 bg-blue-500 text-white p-2 border rounded-lg shadow":"flex flex-row items-center gap-4 hover:text-blue-500 hover:cursor-pointer"} onClick={()=>{setView('brands');setclicked([false,false,false,false,true,false])}}>
          <MdOutlineBrandingWatermark   className='text-2xl w-8' />
          <span className='text-xl'>Marques</span>
        </div>
        <hr className='w-full border-t-2 border-slate-300'/>
        <div className={clicked[5]?"flex flex-row items-center gap-4 bg-blue-500 text-white p-2 border rounded-lg shadow":"flex flex-row items-center gap-4 hover:text-blue-500 hover:cursor-pointer"} onClick={()=>{setView('contact');setclicked([false,false,false,false,false,true])}}>
          <MdOutlineBrandingWatermark   className='text-2xl w-8' />
          <span className='text-xl'>Contact</span>
        </div>
        <div className='flex flex-row items-center gap-4'>
          <IoMdLogOut   className='text-2xl w-8' />
          <span className='text-xl cursor-pointer' onClick={()=>{navigate('/admin/login')
            localStorage.clear()
          }
          }>se deconnecter</span>
        </div>
      </div>
    </div>
    <div className='w-full h-full border rounded-lg'>
    {manageView()}
    
    
    </div>
    </div>
  );
}

export default DashNav;
