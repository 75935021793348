import React from 'react';
import ProductsOnSale from './ProductOnSale';
import Brands from '../Brands';
import Blogs from './Blogs';
import Delivery from './Delivery';
import Pack from '../Packs';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import axios from 'axios'
import vid from '../../images/spiravid.mp4'
import { setUser } from '../../redux/user';
import { useNavigate } from 'react-router-dom';
const HomePage = () => {
const navigate=useNavigate()
  useEffect(()=>{
    const time=localStorage.getItem('rea')
    if (Date.now()-Date.now(time)>=24){
      navigate('/register')
      localStorage.clear()
    }
  },[])
  return (
    <div className="relative flex flex-col min-h-screen gap-12">
      {/* Background Image */}
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <video
        autoPlay
        muted
        loop
        src={vid}
        style={{
          width: "100%", // Adjust width to reduce size
          objectFit: "cover", // Maintain aspect ratio and crop if necessary
          borderRadius: "10px", // Optional: Add rounded corners
        }}
      >
        Your browser does not support the video tag.
      </video>
    </div>

      {/* Content */}
      <div className="flex-grow mt-[150px]">
        <ProductsOnSale />
      </div>
      <hr className="mx-auto my-4 w-3/4 border-[#fef9f3] rounded"/>
      <div>
      <Brands/>
      </div>
      <hr className="mx-auto my-4 w-3/4 border-[#fef9f3] rounded"/>
      <div>
        <Pack/>
      </div>
      <div>

      <Blogs/>
      </div>
      <div>
      <Delivery/>
      </div>
     
    </div>
  );
};

export default HomePage;
