import React, { useState } from 'react';
import axios from 'axios';

import './contact.css';
import ContactMap from './ContactMap';
import { FaInstagram } from "react-icons/fa";
import { AiOutlineFacebook } from "react-icons/ai";

function ContactUs() {
  const [formData, setFormData] = useState({
    email: '',
    subject: '',
    message: ''
  });

  const [submissionStatus, setSubmissionStatus] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://spira-nature.com/api/contact/addContacts', {
        email: formData.email,
        name: formData.subject,
        message: formData.message
      });
      if (response.status === 201) {
        setSubmissionStatus('Your message has been sent successfully!');
        setFormData({
          email: '',
          subject: '',
          message: ''
        });
      }
    } catch (error) {
      setSubmissionStatus('An error occurred while sending your message. Please try again later.');
    }
  };
  console.log("data",formData)

  return (
    <div>
      <div className="contact-container">
        <div className="full-background">
          <div className="lg:mr-[40%] lg:mt-2 mt-8 px-4 content-container">
            <section> 
              <div className="flex space-x-2 text-5xl font-bold mb-6">
                <h2>Entrer en</h2>
                <h2 className="text-[#d2eae5]">Contact</h2>
              </div>
              <div className="mb-6">
                <h1>Vous avez un problème technique ? Vous souhaitez envoyer des commentaires sur une fonctionnalité bêta ? Faites le nous savoir.</h1>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mb-6">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium">Votre email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="shadow-sm border border-gray-300 text-sm rounded-lg focus:border-primary-500 w-full md:w-[70%] p-2.5 dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="SpiraNature@WebSite.com"
                    required
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600">
                    Sujet
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    className="block p-3 w-full md:w-[70%] text-sm bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:border-primary-500 dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Dites-nous comment nous pouvons vous aider"
                    required
                  />
                </div>
                <div className="sm:col-span-2 mb-6">
                  <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">
                   Votre Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    rows={6}
                    className="block p-2.5 w-full md:w-[70%] text-sm text-black bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-100 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Laissez un commentaire..."
                    required
                  ></textarea>
                </div>
                <button type="submit" className='button mb-[4%]'>Soumettre</button>
              </form>
              {submissionStatus && <p>{submissionStatus}</p>}
              <div className="hidden lg:block">
                <ContactMap />
              </div>
              <div className="phone-section mb-[4%] flex flex-col md:flex-row md:items-center md:justify-between">
                <div className="flex items-center mb-4 md:mb-0">
                  <div className="phone-icon">
                    <img src="/phone_PNG48988.png" alt="" />
                  </div>
                  <div className="phone-number ml-4">
                    <h1>Telephone <br /></h1><h1 className='text-teal-700'>98 744 600</h1>
                  </div>
                </div>
                <div className="flex items-center mb-4 md:mb-0">
                  <div className="phone-icon">
                    <img src="/OIP.jpg" alt="" />
                  </div>
                  <div className="phone-number ml-4">
                    <h1 className=''>Email <br /></h1> <h1 className='text-teal-700'>contact@spira-nature.com</h1>
                  </div>
                </div>
               
                <div className="social-icons flex space-x-4">
                  <a href="https://www.facebook.com/spiranature" target="_blank" rel="noopener noreferrer" className="icon facebook-icon">
                    <AiOutlineFacebook size={34} />
                  </a>
                  <a href="https://www.instagram.com/spira.nature/" target="_blank" rel="noopener noreferrer" className="icon instagram-icon">
                    <FaInstagram size={34} />
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;