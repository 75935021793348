import React, { useState,useContext } from 'react';
import axios from 'axios';
import { useObj } from '../context';
import Flouci from './Flouci';
import { useNavigate } from 'react-router-dom';
import { AmountContext } from '../context1'
import { ObjContext } from '../context'
const Formulaire = () => {
    const {obj} =useContext(ObjContext)
    console.log('first',obj)
    const [data, setData] = useState({
        name: "",
        prenom: "",
        telephone: "",
        email: "",
        adresse: "",
        note: "",
        ville: '',
        status:"",
        quantity:obj,
        paiementRef:"a"
    });

    const [errors, setErrors] = useState({});

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value
        }));
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(data).forEach((key) => {
            if (!data[key]) {
                newErrors[key] = 'This field is required';
            }
        });
        return newErrors;
    };
    const navigate=useNavigate()
    const submit = () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            console.log('first')
            setErrors(validationErrors);
        } else {
            if (data.quantity.length===0){
                navigate('/cancel')
            }
            else{

                axios.post('https://spira-nature.com/api/payment/form', data)
                .then(r=>navigate('/success'))
                .catch(err => navigate('/cancel'));
            }
        
       
    }
        
    }
    const {amount}=useContext(AmountContext)
    const pay=()=>{
        axios.post('https://spira-nature.com/api/init-payment',{amount:`${amount?8000+amount*1000:0}`})
        .then(async r=>{
            console.log(r.data)
           var updatedData={
                ...data,
                paiementRef:r.data.paymentRef

            }
            
            await axios.post('https://spira-nature.com/api/payment/form', updatedData)
            .catch(err => navigate('/cancel'));
            window.location=r.data.payUrl
          
        }).catch(err=>{
            navigate('/cancel')
        })
      
        
    }
    return (
        <div>

            <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
                <h1 className=' font-semibold text-red-600 text-[13px]'>Note : <br/> <span>Pour tout paiement en ligne, des frais de livraison de 8 DT s'appliquent.</span></h1>
                <h2 className="text-2xl font-bold mb-4 text-center">Formulaire</h2>
                <div className="space-y-4" style={{ maxHeight: '480px', overflowY: 'auto' }}>
                    <input
                        type="text"
                        name="name"
                        value={data.name}
                        onChange={handleOnChange}
                        placeholder="Nom"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                    <input
                        type="text"
                        name="prenom"
                        value={data.prenom}
                        onChange={handleOnChange}
                        placeholder="Prénom"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.prenom && <p className="text-red-500 text-xs mt-1">{errors.prenom}</p>}
                    <input
                        type="email"
                        name="email"
                        value={data.email}
                        onChange={handleOnChange}
                        placeholder="Email"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                    <input
                        type="text"
                        name="telephone"
                        value={data.telephone}
                        onChange={handleOnChange}
                        placeholder="Téléphone"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.telephone && <p className="text-red-500 text-xs mt-1">{errors.telephone}</p>}
                    <input
                        type="text"
                        required
                        name="ville"
                        value={data.ville}
                        onChange={handleOnChange}
                        placeholder="Ville"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.ville && <p className="text-red-500 text-xs mt-1">{errors.ville}</p>}
                    <input
                        type="text"
                        name="adresse"
                        value={data.adresse}
                        onChange={handleOnChange}
                        placeholder="Adresse"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.adresse && <p className="text-red-500 text-xs mt-1">{errors.adresse}</p>}
                    <input
                        type="text"
                        name="note"
                        value={data.note}
                        onChange={handleOnChange}
                        placeholder="Note"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.note && <p className="text-red-500 text-xs mt-1">{errors.note}</p>}
                    
                </div>
                <div className="mt-6 flex justify-evenly">
                    
                    <button
                    
                     onMouseEnter={() => setData(prev => ({ ...prev, status: "flouci" }))}
                    onClick={()=>pay()}
                        className="button h-[60px]">
                            Konnect
                    </button>
                    <button
                     onMouseEnter={() => setData(prev => ({ ...prev, status: "livraision" }))}
                     onClick={()=>submit()}
                     className="button ml-10 h-[60px]">
                        Payment A La Livraison
                    </button>
                            </div>
            </div>

        </div>
    );
};

export default Formulaire;
