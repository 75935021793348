import React, { useEffect } from 'react'
import Details from './Details'
import DashboardChart from './DashboardChart'
import { useNavigate } from 'react-router-dom'
function Dash() {
  const navigate=useNavigate()
  useEffect(()=>{
    let x=localStorage.getItem('isSpira')
    if (x!=="true1"){
      navigate('/admin/login')
    }
  },[])
  return (
    <div className="bg-[#d2eae5] w-[80%] ml-[20%]">
    <div className='flex flex-col gap-4 p-8'>
    <div>
    <h1 className='text-3xl'>Dashboard</h1>
    </div>
    <Details/>
</div>
<div className='flex justify-center h-full'>
    <DashboardChart/>
</div> 
</div>
  )
}

export default Dash