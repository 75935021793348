import React, { useState, useEffect ,useContext} from 'react';
import axios from 'axios';
import { ObjContext } from './context';
import { useNavigate } from 'react-router-dom';
function Pack() {
  
  const [blogs, setBlogs] = useState([]);
  const { obj, setObj,load,setLoad } = useContext(ObjContext);
  const token = localStorage.getItem('token');
  const[user,setUser]=useState([])
  useEffect(()=>{
    axios.post('https://spira-nature.com/api/user/user-details',{token})
    .then(r=>
      {
      setUser(r.data)}).catch(err=>{
        // navigate('/register')
      })
  },[])
const navigate=useNavigate()
  const addToCart = (p) => {
    setLoad(!load)
    if (!localStorage.getItem('token')){
      navigate('/register')
    }
    axios.post('https://spira-nature.com/api/cart/add/', p)
    .catch(err =>{
        alert('Essayer un autre fois')
        navigate('/')

      });
  };
  useEffect(() => {
    // Fetch the packs from the backend
    axios.get('https://spira-nature.com/api/product/getPacks') // Adjust the URL as per your backend endpoint
      .then(response => {
        setBlogs(response.data);
      })
      .catch(error => console.error('Error fetching packs:', error))},[])
      const handleQuantityChange = (id) => {
        setObj(prevObj => {
          if (!Array.isArray(prevObj)) {
            return [{ id, quantity: 1 }];
          }
          const existingItemIndex = prevObj.findIndex(item => item.id === id);
          if (existingItemIndex !== -1) {
            const updatedObj = [...prevObj];
            updatedObj[existingItemIndex].quantity += 1;
            return updatedObj;
          } else {
            return [...prevObj, { id, quantity: 1 }];
          }
        });
      };






  return (
    <div className='p-8'>
      <div className='flex flex-row items-center gap-2'>
        <h2 className="text-7xl text-[#0072a4] md:ml-16 font-brownhill font-bold">Nos Packs</h2>
      </div> 
      <div className="flex flex-wrap justify-center gap-32 mt-[30px] mb-[30px]">
        {blogs.slice(0,4).map((blog, index) => (
          <div key={index} className="bg-[#fef9f3] border border-gray-300 rounded-lg shadow-md p-4 w-[300px]">
            {console.log(blog)}
            <img
  src={
    (() => {
      try {
        const images = JSON?.parse(blog?.images);
        return images && images.length > 0 ? images[0] : "default-image-url.jpg";
      } catch (error) {
        console.error("Invalid JSON format for images:", error);
        return "default-image-url.jpg"; // Fallback image
      }
    })()
  }
  alt="Blog post"
  className="w-full h-[250px] object-cover rounded-lg mb-4"
/>

            <h2 className="text-lg font-bold mb-2">{blog.name}</h2>
            <div className="text-[#0072a4] mb-2">{blog.price}DT</div>
            <button onClick={()=>{
              addToCart({ ProductID: blog.idpoducts, userID: user.idusers })
              handleQuantityChange(blog.idpoducts)}} className="button ">Ajouter Au Panier</button>
          </div>
        ))}

        
      </div>
    </div>
  );
}

export default Pack