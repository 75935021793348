import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MdDeleteOutline } from "react-icons/md";
import p1 from '../../images/305260564_457640209714476_698861678149019837_n-removebg-preview.png'
const Clients = () => {
  const [clients, setClients] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [load1, setLoad1] = useState(false);
  const [index, setIndex] = useState(-1);
  const[idformulaire,setIdformulaire]=useState(-1)
  const del = (id) => {
    
      axios.delete('https://spira-nature.com/api/payment/del/' + id).then(r => {
        setLoad1(!load1)
      })
     
    .catch(err => 
      setLoad1(!load1)
    )
  }

  useEffect(() => {
    fetchClients();
  }, [load1]);
  
  const fetchClients = async () => {
    try {
      const response = await axios.get('https://spira-nature.com/api/payment/get');
      const clientData = response.data;
    
      // Fetch payment details for each client and update their data
      const updatedClients = await Promise.all(
        clientData.map(async (e) => {
          try {
            const r = await axios.get(`https://api.konnect.network/api/v2/payments/${e.paiementRef}`);
            return {
              ...e, // Original client data
              note: r.data.payment.status, // Add or update `note`
            };
          } catch (err) {
            console.error(`Error fetching payment for ref ${e.paiementRef}:`, err);
            return {
              ...e, // Preserve original data even if the payment fetch fails
              note: 'Error fetching payment',
            };
          }
        })
      );
    
      // Update the state with the new client data
      setClients(updatedClients);
    
      // Optionally log the updated clients
      console.log(updatedClients,'aze');
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const fetchOrderDetails =async(idformulaire) => {
    const clientResponse = await axios.get(`https://spira-nature.com/api/payment/get/${idformulaire}`);
    setOrderDetails(JSON.parse(clientResponse.data.quantity))
    setPopupVisible(true);
   
  };

  

  const closePopup = () => {
    setPopupVisible(false);
   
  };

  const 
  
  
  handlePrint = async (idformulaire) => {
    try {
      // Fetch client data from API
      const clientResponse = await axios.get(`https://spira-nature.com/api/payment/get/${idformulaire}`);
      const client = clientResponse.data;

      // Build the printable content using client data
      const logo=p1
      const content = `
        <div style="background-color: white; padding: 24px; max-width: 600px; margin: 0 auto; border: 1px solid #e5e7eb; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
         
         <div style="text-align: center; margin-bottom: 24px;">
          <img src="${logo}" alt="Logo" style="width: 100px;height:100px; margin-bottom: 16px;" />
          <h1 style="font-size: 24px; font-weight: bold; color: #1e3a8a;">Reçu de Paiement</h1>
        </div>
          <div style="margin-bottom: 24px;">
            <h2 style="font-size: 18px; font-weight: 600; color: #4b5563;">Facturé à :</h2>
            <div style="margin-top: 8px; font-size: 14px; color: #6b7280;">
              <p><span style="font-weight: bold;">Nom:</span> ${client.name || "John Doe"}</p>
              <p><span style="font-weight: bold;">Adresse:</span> ${client.adresse || "Adresse inconnue"}</p>
              <p><span style="font-weight: bold;">Date:</span> ${new Date(client.createdAt)}</p>
              <p><span style="font-weight: bold;">Méthode de paiement:</span> 
                <span style="color: #fbbf24;font-weight: bold; padding: 4px 8px; border-radius: 4px;width:fit-content">
                  ${client.status==='flouci'?'Carte':"livraison" || "Carte"}
                </span>
              </p>
            </div>
          </div>
          <div>
            <h2 style="font-size: 18px; font-weight: 600; color: #4b5563; margin-bottom: 8px;">Produits</h2>
            <table style="width: 100%; border-collapse: collapse; border: 1px solid #e5e7eb;">
              <thead>
                <tr style="background-color: #dbeafe;">
                  <th style="border: 1px solid #e5e7eb; padding: 8px; color: #374151;">Produit</th>
                  <th style="border: 1px solid #e5e7eb; padding: 8px; color: #374151;">Quantité</th>
                 
                </tr>
              </thead>
              <tbody>
                ${JSON.parse(client.quantity)
                  .map(
                    (product) => `
                    <tr style="text-align: center;">
                      <td style="border: 1px solid #e5e7eb; padding: 8px;">${product.name}</td>
                      <td style="border: 1px solid #e5e7eb; padding: 8px;">${product.quantity}</td>
                    
                    </tr>`
                  )
                  .join("")}
              </tbody>
            </table>
          </div>
        </div>
      `;
  
      // Open a new window and print the content
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <title>Reçu de Paiement</title>
          </head>
          <body>${content}</body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
      const img = printWindow.document.querySelector("img");
      img.onload = () => {
        printWindow.print();
        printWindow.close();
      };
    } catch (error) {
      console.error("Erreur lors de l'impression :", error);
    }
  };
 
  return (
    <div className="p-4 bg-[#d2eae5] h-full w-[80%] ml-[20%]">
      <h2 className="text-xl font-bold mb-4">Clients</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">Nom Complet</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">Adresse</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">Numéro du Téléphone</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">Payment</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">Payment status</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {clients.map((client, index) => (
              <tr key={index}>
                <td onClick={() => {
                  fetchOrderDetails(client.idformulaire)
                  setIndex(index)
                } 
                }
                  className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                  
                  >{`${client.prenom} ${client.name}`}</td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">{client.adresse}</td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">{client.telephone}</td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">{client.status==="flouci"?"en ligne":"a la livraison"}</td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">{client.createdAt.slice(0,10)}</td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 ">{client.note==="pending"?<h1 className='rounded-md font-semibold text-white text-[17px] flex justify-center items-center bg-[#cbb329d3]'>pending</h1>:(client.note==="cancel"?<h1 className='rounded-md bg-[red] text-[17px] flex justify-center items-center font-semibold text-white'>cancel</h1>:<h1 className='rounded-md bg-[green] text-white font-semibold  text-[17px] flex justify-center items-center'>success</h1>)}</td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"><div className='flex gap-4 ' ><h1 className=' cursor-pointer'onClick={() => handlePrint(client.idformulaire)}>Print</h1><MdDeleteOutline className='mt-1 cursor-pointer' onClick={() => del(client.idformulaire)} /></div></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {popupVisible && orderDetails && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded shadow-lg">
      <h3 className="text-lg font-bold mb-4">Détails de la commande</h3>
      {orderDetails?.map((order, index) => (
        <div key={index} className="mb-2">
          <p>Nom du produit: {order.name}</p>
          <p>Quantité: {order.quantity}</p>
        </div>
    ))}
    {(() => {
      const parsedClients = JSON.parse(clients[index]?.quantity);

      // Calculate the total quantity of items purchased
const totalQuantity = parsedClients.reduce((sum, e) => sum + e.quantity, 0);

// Check if a discount applies (totalQuantity > 3 means a discount applies)
const discount = totalQuantity >= 3 ? 0.9 : 1;

// Calculate the total amount with or without the discount
const totalAmount = parsedClients.reduce(
  (sum, e) => sum + e.price * e.quantity * discount,
  0
); // Use reduce to sum the prices
      return <h1>Montant à payer: {clients[index]?.status==="flouci"?totalAmount+8:totalAmount} DT</h1>;
    })()}
      
      
      {/* Calculate total amount */}

      <button className="button mt-4 px-4 py-2 bg-blue-500 text-white rounded" onClick={closePopup}>
        Close
      </button>
    </div>
  </div>
)}

    </div>
  );
};

export default Clients;