import '../Blogs/button.css';
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../redux/user';
import { Link } from 'react-router-dom';
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { ObjContext } from '../context';
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
const ProductsOnSale = () => {
  const [products, setProducts] = useState([]);
  const[heart,setHeart]=useState(false)
  const[user,setUser]=useState(null)
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const { obj, setObj,setLoad,load } = useContext(ObjContext);
const navigate=useNavigate()
  useEffect(()=>{
    axios.post('https://spira-nature.com/api/user/user-details',{token})
    .then(r=>
      {

        setUser(r.data.idusers)
      }).catch(err=>{
        // navigate('/register')
      })
  },[])



  const addToCart = (p) => {
    setLoad(!load)
    if (!localStorage.getItem('token')){
      navigate('/register')
    }
    else{

    axios.post('https://spira-nature.com/api/cart/add/', p)
      .then(r => console.log(r)).catch(err =>{
        alert('Essayer un autre fois')
        navigate('/')

      });
  }}

  const addToWishlist = (productId) => {
    const token=localStorage.getItem('token')
    if (!token){
      navigate('/register')
    }
    else{
    const p = { productID: productId, userID: user };
    axios.post('https://spira-nature.com/api/wish/add', p).then(r=>{})
      .catch(err => {
        alert('Le Produit Est Deja Ajouté')
        navigate('/')
      });
  }}
  const [index,setIndex]=useState(-1)
  const handleQuantityChange = (id) => {
    setObj(prevObj => {
      if (!Array.isArray(prevObj)) {
        return [{ id, quantity: 1 }];
      }
      const existingItemIndex = prevObj.findIndex(item => item.id === id);
      if (existingItemIndex !== -1) {
        const updatedObj = [...prevObj];
        updatedObj[existingItemIndex].quantity += 1;
        return updatedObj;
      } else {
        return [...prevObj, { id, quantity: 1 }];
      }
    });
  };

  useEffect(() => {
    axios.get('https://spira-nature.com/api/product/getAllProd').then(r => {
      console.log(r.data)
      setProducts(r.data);
    }).catch(err =>{
      
      navigate('/')
    });
  }, []);

  return (
    <div className="p-8">
      <div className='flex flex-row items-center gap-2'>
        <h2 className="text-7xl text-[#0072a4] md:ml-16 font-brownhill font-bold">Nos Promos</h2>
      </div>
      <div className="flex flex-col gap-12 container mx-auto mt-12 px-4">
        <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12">
          {products?.filter((e,i)=>e?.sale )?.slice(0,4)?.map(product => (
            <div key={product?.idpoducts} className="bg-[#fef9f3] p-6 rounded-lg overflow-hidden w-[300px] relative">
              <span className="bg-green-500 text-white px-2 py-1 rounded-full absolute top-0 left-0 m-2">Sale</span>
              <Link to={`/details/${product?.idpoducts}`} className="cursor-pointer">
                <img src={JSON.parse(product.images)[0]} alt={product?.name} className="w-full h-[250px] object-cover rounded-t-lg" />
              </Link>
              <div className="px-4 py-2 ">
                <h3 className="text-xl font-semibold mb-2">{product?.name}</h3>
                <p className="text-sm text-gray-600 truncate mb-2">{product?.description}</p>
                
                {product?.sale?
                    <div className='flex gap-[10px]'>
                    <h1 className='text-[#0072a4]'>{product?.price-((product?.price*product?.sale)/100)} DT</h1>
                    <h1 className=' line-through'>{product?.price} DT</h1>
                    </div>:
                    <h1 className='text-[#0072a4]'>{product?.price} DT</h1>

                } 
            
            <div className='flex flex-row gap-2  justify-end'>
                  <AiOutlineShoppingCart className="cursor-pointer text-[#0072a4]"  size={30} onClick={() => {
                    addToCart({ ProductID: product.idpoducts,userID:user});
                    handleQuantityChange(product.idpoducts);
                  }}/>
                 
                    {!heart||index!==product.idpoducts?<FaRegHeart
                    onClick={()=>{
                      setIndex(product.idpoducts)
                      setHeart(true)
                      addToWishlist(product.idpoducts)
                    }}
                    className='cursor-pointer  text-[#0072a4]' size={30}  />
                  :index===product.idpoducts&&<FaHeart size={30} color='#3b82f6' />
                  }
                </div>

              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductsOnSale;
