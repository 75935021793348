import React, { useState,useContext,useEffect } from 'react';
import { IoSearch } from "react-icons/io5";
import { IoIosSearch, IoIosMenu, IoIosClose } from "react-icons/io";
import { AiOutlineLogout } from "react-icons/ai";
import { IoIosHeartEmpty } from "react-icons/io";
import { FaUserLarge } from "react-icons/fa6";

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import './Navbar.css';
import { HiOutlineShoppingBag } from "react-icons/hi2";
import Modal from 'react-modal';
import { ObjContext, ObjProvider, TextContext, TextProvider } from './context';
import axios from 'axios'
import { urlencoded } from 'body-parser';
const modalStyle = {
  content: {
    top: '25%',
    left: '0',
    right: 'auto',
    bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
    overflow:"visible"
    
  },
};
const Navbar = () => {
    const {load}=useContext(ObjContext)
    const navigate=useNavigate()
    const [search, setSearch] = useState(true);
   const [modalOpen, setModalOpen] = useState(false);
    const[text,setText]=useState("")
    const [length,setLength]=useState(0)
    useEffect(() => {
        const token = localStorage.getItem('token');
        
    
        // Fetch user details
        axios.post('https://spira-nature.com/api/user/user-details', { token })
          .then(response => {
            const userId = response.data.idusers;
            // Fetch cart items for the user
            axios.get(`https://spira-nature.com/api/cart/panier/${userId}`)
              .then(cartResponse => {
                    setLength(cartResponse.data.length)
              })
              .catch(error => {
              
              });
          })
          .catch(error => {
        
          });
      }, [load]);
// console.log(decodeURIComponent('ben%20slim'))
    return (
        <div className='fixed top-0  w-full bg-[#d2eae5] z-10'>
            {/* this need to be its own component like <LimitedOffer text= "...." /> */}
            <div className='w-full h-[40px] font-semibold text-[12px] flex justify-center items-center bg-black text-white'>
                <h1>Achetez 3 articles et obtenez 10 % de réduction - Offre à durée limitée</h1>
            </div>

            <div >
                <div className='flex justify-between items-center p-6 text-[16px] md:text-[20px]'>

                {/*in tailwinds always adopt mobile first approche */}
                {/*first dev for mobile then adapt for bigger screen - with this aproche you will always end up with responsive design */}
                {/*in the code below we applied this principle: "hidden"  is applied by defautl cause we want it hidden on mobile */}
                {/*then we adapt for bigger screen with breakpoints like "md:"(medium screen) and we set it visible with flex  */}
                {/* https://tailwindcss.com/docs/responsive-design */}
                    <div className='hidden md:flex gap-10 flex-1'>
                    <Link to={'/'}>
                            <h1 className='cursor-pointer hover:underline'>Accueil</h1>
                        </Link>
                        <Link to={'/shop'}>
                            <h1 className='cursor-pointer hover:underline' >Boutique</h1>
                        </Link>
                      <Link to={'/about'}><h1 className='cursor-pointer hover:underline' >À propos</h1></Link> 
                        <Link to={'/contact'}>
                            <h1 className='cursor-pointer hover:underline'>Contact</h1>
                        </Link>
                    </div>

                    {/* here we show the burger menu icon, notice it set to visible by default with "flex" and hidden on larger screen with "md:hidden" it's cause we develop for mobile first*/}
                      <div className='flex md:hidden gap-10 flex-1'>
                      {/* you can also make this button open a dropdown and set your menu(shop/About/Contact) vertically  */}
                        <IoIosMenu size={25} onClick={() => setModalOpen(true)} className='cursor-pointer relative'  />
            <Modal
            isOpen={modalOpen}
            style={modalStyle}
            contentLabel="menu Modal" >
            <div className='flex gap-10 flex-1 relative '>
                    <Link to={'/'}>
                            <h1 className='cursor-pointer hover:underline'>Accueil</h1>
                        </Link>
                        <Link to={'/shop'}>
                            <h1 className='cursor-pointer hover:underline' >Boutique</h1>
                        </Link>
                     <Link to={'/about'}><h1 className='cursor-pointer hover:underline' >À propos</h1></Link>  
                        <Link to={'/contact'}>
                            <h1 className='cursor-pointer hover:underline' >Contact</h1>
                        </Link>
                    </div>
                    <div   onClick={()=> setModalOpen(false)} style={{position: "absolute", top:-10, right:-10, padding:"2px", backgroundColor:"white", border:"1px solid black", borderRadius:"999px"}}>
                    <IoIosClose size={25} className='cursor-pointer'/>
                    </div>
      </Modal>                   
       </div>


                    <div className='flex flex-1 justify-center align-center'>
                        {/* use w-40 instead of w-[40vh] for exemple, it's better for responsive */}
                         <Link to={'/'}>
                         <img src="\spiranature.png" 
                         alt="spiranature"
                         className='w-40 h-20 object-cover' />
                        </Link>
                    </div>

                    <div className='flex flex-1 flex-row items-center gap-8 justify-end'>
                        {search && <IoIosSearch size={25} className='cursor-pointer' onClick={() => setSearch(!search)} />}
                        {
                            !search &&
                            <div className='flex gap-2 bg-white rounded'>
                                <input
                                    type="text"
                                    placeholder='Recherche par produit ..'
                                    className='rounded-[6px]  pl-6 outline-none'
                                    onChange={(e)=>setText(e.target.value)}
                                />
                                <div className='mt-1 gap-[3px] flex justify-center align-center'>
                                    <IoIosClose size={25} className='cursor-pointer'onClick={() => setSearch(!search)} />
                                    <IoIosSearch size={20} className='cursor-pointer mt-0.5' onClick={()=>{
                                        
                                        navigate('/display/?query='+text)}} />
                                </div>
                            </div>
                        }
                        <Link to="/cart">
                            <div 
                            
                        className='w-[15px] h-[15px] p-2 ml-4 fixed  text-[12px] rounded-full bg-[#0072a4] flex justify-center items-center text-white'>{length}</div>
                            <HiOutlineShoppingBag size={25} className='cursor-pointer' />
                           

                        </Link>
                        <Link to="/liste-de-souhaits">
                            <IoIosHeartEmpty size={25} className='cursor-pointer' />
                           

                        </Link>
                      {
                        localStorage.getItem('token')?
                        <Link to='/register'><AiOutlineLogout onClick={()=>localStorage.clear('token')} size={25} className='cursor-pointer'/></Link>  
                        :
                        <Link to={'/register'}><FaUserLarge  size={25} className='cursor-pointer'/></Link>

                      }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;