import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
const ProductDisplay = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Tous');
  const [sortOrder, setSortOrder] = useState('');
  const location=useLocation()
  useEffect(() => {
    // Fetch products from the backend
    axios.get('https://spira-nature.com/api/product/getAllProd')
      .then(response => {
        setProducts(response.data);
        setFilteredProducts(response.data);

        // Extract categories and remove duplicates
        const uniqueCategories = ['Tous', ...new Set(response.data.map(product => product.category))];
        setCategories(uniqueCategories);
      })
      .catch(error => console.log(error));
  }, []);
  
  // console.log(decodeURIComponent(location.search.split('=')[1]),products[76].marque==decodeURIComponent(location.search.split('=')[1]))
  useEffect(() => {
    let updatedProducts = products;
    if (location.search.split('=')[1]){
      updatedProducts = updatedProducts.filter(product => product?.marque == `${decodeURIComponent(location.search.split('=')[1])}`);
      if (!updatedProducts){
        updatedProducts = updatedProducts.filter(product => product?.name == `${decodeURIComponent(location.search.split('=')[1])}`);
      }
    }
    // Filter by category or type
    if (selectedCategory !== 'Tous') {
      if (selectedCategory === 'Packs') {
        updatedProducts = updatedProducts.filter(product => product.type === 'packet');
      } else {
        console.log('first')
        updatedProducts = updatedProducts.filter(product => product.category == selectedCategory);
      }
    }

    // Sort by price
    if (sortOrder === 'asc') {
      updatedProducts = updatedProducts.sort((a, b) => a.price - b.price);
    } else if (sortOrder === 'desc') {
      updatedProducts = updatedProducts.sort((a, b) => b.price - a.price);
    }

    setFilteredProducts(updatedProducts);
  }, [selectedCategory, sortOrder, products]);

  return (
    <div className="flex flex-col p-5 space-y-6">
      {/* Filter Navbar */}
      <div className="bg-[#fef9f3] w-full p-4 border border-gray-300 rounded-lg shadow-md">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4 sm:mb-0">Filters</h2>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <select
              className="py-2 px-4 rounded-lg border border-[#0072a4] bg-white"
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="">Trier par prix</option>
              <option value="asc">Prix : haut à bas</option>
              <option value="desc">Prix : ​​Croissant</option>
            </select>
            <ul className="flex flex-wrap space-x-4">
            <select
  className="button bg-white py-2 px-4 rounded-lg font-semibold"
  value={selectedCategory}
  onChange={(e) => {
    setSelectedCategory(e.target.value);
    console.log('first');
  }}
>
  {categories.map((category, index) => (
    <option
      key={index}
      value={category}
      className={`${
        selectedCategory === category
          ? 'bg-[#0072a4] text-white'
          : 'text-gray-700 hover:bg-blue-100'
      }`}
    >
      {category}
    </option>
  ))}
</select>
              <li>
                <button
                  className={`button bg-white py-2 px-4 rounded-lg font-semibold transition-colors duration-300 ${
                    selectedCategory === 'Packs' ? 'bg-[#0072a4] text-white' : 'button bg-white text-gray-700 hover:bg-blue-100'
                  }`}
                  onClick={() => setSelectedCategory('Packs')}
                >
                  Packs
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Product Display Section */}
      <div className="bg-[#fef9f3] w-full p-6 border border-gray-300 rounded-lg shadow-md">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:ml-32">
          {filteredProducts.map(product => (
            <div key={product.id} className="w-[70%] p-4 bg-white rounded-lg border border-gray-200  shadow-md hover:shadow-lg transition-shadow duration-300">
               <Link to={`/details/${product.idpoducts}`} >
              <img src={JSON.parse(product.images)[0]} alt={product.name} className="w-full h-56 object-cover rounded-t-lg mb-4" />
              </Link>
              <h3 className="text-lg font-bold text-gray-800">{product.name}</h3>
              <p className='text-sm text-gray-600 truncate'>{product.description}</p>
              <p className="text-[#0072a4] font-bold">{product.price} DT</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDisplay;