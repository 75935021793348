import React, { useState, useEffect } from 'react';
import { MdDeleteOutline } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import axios from 'axios';

function Product() {
  const [products, setProducts] = useState([]);
  const [showAddProductForm, setShowAddProductForm] = useState(false);
  const [showUpdateProductForm, setShowUpdateProductForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    description: '',
    quantity: '',
    type: '',
    category: '',
    sale: ''
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editProductId, setEditProductId] = useState(null);
  const [refreshProducts, setRefreshProducts] = useState(false);
  const[upload,setUpload]=useState(false)
  useEffect(() => {
    fetchProducts();
  }, [refreshProducts]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://spira-nature.com/api/product/getAllProd');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    try {
      setUpload(true)
      // Upload images to Cloudinary
      const imageUrls = await uploadImages(selectedFiles);

      // Add image URLs to form data
      const productData = {
        ...formData,
        images: imageUrls
      };

      const response = await axios.post('https://spira-nature.com/api/product/add', productData);
      if (response.status === 201) {
        setFormData({
          name: '',
          price: '',
          description: '',
          quantity: '',
          type: '',
          category: '',
          sale: '',
          marque:''
        });
        setSelectedFiles([]);
        setUpload(false)
        setShowAddProductForm(false);
        setRefreshProducts(!refreshProducts);
      } else {
        console.error('Failed to add product');
      }
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    try {
      setUpload(true)
      let imageUrls = formData.images || [];

      // Upload new images if any
      if (selectedFiles.length > 0) {
        imageUrls = await uploadImages(selectedFiles);
      }

      // Add image URLs to form data
      const productData = {
        ...formData,
        images: imageUrls
      };

      const response = await axios.put(`https://spira-nature.com/api/product/put/${editProductId}`, productData);
      if (response.status === 200) {
        setFormData({
          name: '',
          price: '',
          description: '',
          quantity: '',
          type: '',
          category: '',
          sale: '',
          marque:''
        });
        setSelectedFiles([]);
        setUpload(false)
        setShowUpdateProductForm(false);
        setRefreshProducts(!refreshProducts);
      } else {
        console.error('Failed to edit product');
      }
    } catch (error) {
      console.error('Error editing product:', error);
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      const response = await axios.delete(`https://spira-nature.com/api/product/del/${id}`);
      if (response.status === 204) {
        setRefreshProducts(!refreshProducts);
      } else {
        console.error('Failed to delete product');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleEditProduct = (id) => {
    setEditProductId(id);
    const productToEdit = products.find(product => product.idpoducts === id);
    setFormData(productToEdit);
    setShowUpdateProductForm(true);
  };

  const uploadImages = async (files) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('img', files[i]);
      console.log("first",formData)
    }

    try {
      const response = await axios.post('https://spira-nature.com/api/cloudinary', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data.map(file => file.secure_url);
    } catch (error) {
      console.error('Error uploading images:', error);
      return [];
    }
  };

  return (
    <div className='flex flex-col gap-2 p-8 bg-[#d2eae5] w-[80%] ml-[20%] h-full'>
      {upload&&(<div className="fixed inset-0 flex items-center justify-center bg-white dark:bg-gray-900 bg-opacity-70 dark:bg-opacity-70 z-50">
  <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-opacity-75"></div>
</div>
)}
      <div className='flex flex-row justify-between items-center gap-4'>
        <h1 className='text-3xl'>Produits</h1>
        <div
          className='flex flex-row items-center justify-between gap-4 bg-white p-2 border-2 border-[#0072a4] text-[#0072a4] rounded-lg shadow cursor-pointer'
          onClick={() => setShowAddProductForm(!showAddProductForm)}
        >
          <h1>Ajouter un produit</h1>
          <FaPlus />
        </div>
      </div>
      {showAddProductForm && (
        <div className='bg-[#0072a4] p-6 rounded-lg shadow'>
          <h2 className='text-2xl text-white mb-4'>Ajouter un nouveau produit</h2>
          <form className='flex flex-col justify-center items-center gap-4' onSubmit={handleAddProduct}>
            <input
              type='text'
              name='name'
              placeholder='Nom du produit'
              value={formData.name}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='number'
              name='price'
              step='0.01'
              placeholder='Prix'
              value={formData.price}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <textarea
              name='description'
              placeholder='Description'
              value={formData.description}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='number'
              name='quantity'
              step='0.01'
              placeholder='Quantité'
              value={formData.quantity}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <select
              name='type'
              value={formData.type}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%] text-gray-400'
            >
              <option value='' disabled selected>Sélectionnez le type</option>
              <option value='single'>unique</option>
              <option value='packet'>Pack</option>
            </select>
            <input
              type='text'
              name='category'
              placeholder='Categorie'
              value={formData.category}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='text'
              name='marque'
              placeholder='Marque'
              value={formData.marque}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <div className='flex justify-evenly text-white w-full'>

            <input
              type='number'
              name='sale'
              placeholder='Promotion'
              value={formData.sale}
              onChange={handleInputChange}
              className='p-2 rounded-lg left-0 w-[60%] text-black'
              />
              <h1>Exemple:10%...</h1>
              <h2>nouveau prix: <span>{formData?.price?formData.price-(formData?.price*formData?.sale/100):0}</span></h2>
              </div>
            <input
              type='file'
              name='img'
              onChange={handleFileChange}
              className='p-2 rounded-lg w-[90%]'
              multiple
            />
            <button className='button bg-white text-[#0072a4] w-1/2 border-white rounded'>Ajouter</button>
          </form>
        </div>
      )}

      {showUpdateProductForm && (
        <div className='bg-[#0072a4] p-6 rounded-lg shadow'>
          <h2 className='text-2xl text-white mb-4'>Modifier un produit</h2>
          <form className='flex flex-col justify-center items-center gap-4' onSubmit={handleUpdateProduct}>
            <input
              type='text'
              name='name'
              placeholder='Nom du produit'
              value={formData.name}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='number'
              name='price'
              step='0.01'
              placeholder='Prix'
              value={formData.price}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <textarea
              name='description'
              placeholder='Description'
              value={formData.description}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='number'
              name='quantity'
              step='0.01'
              placeholder='Quantité'
              value={formData.quantity}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <select
              name='type'
              value={formData.type}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%] text-gray-400'
            >
              <option value='' disabled selected>Sélectionnez le type</option>
              <option value='single'>unique</option>
              <option value='packet'>Pack</option>
            </select>
            <input
              type='text'
              name='category'
              placeholder='Categorie'
              value={formData.category}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='number'
              name='sale'
              placeholder='Promotion'
              value={formData.sale}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='file'
              name='img'
              onChange={handleFileChange}
              className='p-2 rounded-lg w-[90%]'
              multiple
            />
            <button className='button bg-white text-[#0072a4] w-1/2 border-white rounded'
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            >Modifier</button>
          </form>
        </div>
      )}

      <div className='flex flex-row flex-wrap justify-center gap-4'>
        {products.map(product => (
          <div key={product.idpoducts} className='flex flex-col justify-center bg-white p-4 w-[30%] border rounded-lg gap-2 shadow'>
<img 
  src={
    (() => {
      try {
        const images = JSON.parse(product?.images);
        return Array.isArray(images) && images.length > 0 ? images[0] : "";
      } catch (error) {
        console.error("Invalid JSON or parsing error:", error);
        return ""; // Return an empty string as a fallback
      }
    })()
  } 
  className="w-full border rounded-lg shadow" 
  alt="Product Image" 
/>
            <div className='flex flex-col gap-2'>
              <h1>{product.name}</h1>
              <span>{product.price} DT</span>
              <div className='flex flex-row justify-between items-center'>
                <button className='button border-[1px] text-[#0072a4] border-[#0072a4] rounded-lg p-2' onClick={() =>{
                  handleEditProduct(product.idpoducts)
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                } 
                >Modifier</button>
                <MdDeleteOutline className='text-2xl text-[#0072a4] hover:cursor-pointer' onClick={() => handleDeleteProduct(product.idpoducts)} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Product;

