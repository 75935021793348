 import React, { useState, useEffect } from 'react';
import { MdDeleteOutline } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import axios from 'axios';

function Blog() {
  const [products, setProducts] = useState([]);
  const [showAddProductForm, setShowAddProductForm] = useState(false);
  const [showUpdateProductForm, setShowUpdateProductForm] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: ''
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editProductId, setEditProductId] = useState(null);
  const [refreshProducts, setRefreshProducts] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, [refreshProducts]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://spira-nature.com/api/blog/get');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    try {
      // Upload images to Cloudinary
      const imageUrls = await uploadImages(selectedFiles);

      // Add image URLs to form data
      const productData = {
        ...formData,
        images: imageUrls
      };

      const response = await axios.post('https://spira-nature.com/api/blog/add', productData);
      if (response.status === 201) {
        setFormData({
          title: '',
          description: ''
        });
        setSelectedFiles([]);
        setShowAddProductForm(false);
        setRefreshProducts(!refreshProducts);
      } else {
        console.error('Failed to add product');
      }
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    try {
      let imageUrls = formData.images || [];

      // Upload new images if any
      if (selectedFiles.length > 0) {
        imageUrls = await uploadImages(selectedFiles);
      }

      // Add image URLs to form data
      const productData = {
        ...formData,
        images: imageUrls
      };

      const response = await axios.put(`https://spira-nature.com/api/blog/put/${editProductId}`, productData);
      if (response.status === 200) {
        setFormData({
          title: '',
          description: ''
        });
        setSelectedFiles([]);
        setShowUpdateProductForm(false);
        setRefreshProducts(!refreshProducts);
      } else {
        console.error('Failed to edit product');
      }
    } catch (error) {
      console.error('Error editing product:', error);
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      const response = await axios.delete(`https://spira-nature.com/api/blog/post/${id}`);
      if (response.status === 204) {
        setRefreshProducts(!refreshProducts);
      } else {
        console.error('Failed to delete product');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleEditProduct = (id) => {
    setEditProductId(id);
    const productToEdit = products.find(product => product.idpoducts === id);
    setFormData(productToEdit);
    setShowUpdateProductForm(true);
  };

  const uploadImages = async (files) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('img', files[i]);
      console.log("first",formData)
    }

    try {
      const response = await axios.post('https://spira-nature.com/api/cloudinary', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data.map(file => file.secure_url);
    } catch (error) {
      console.error('Error uploading images:', error);
      return [];
    }
  };

  return (
    <div className='flex flex-col gap-2 p-8 bg-[#d2eae5] w-[80%] ml-[20%] h-full'>
      <div className='flex flex-row justify-between items-center gap-4'>
        <h1 className='text-3xl'>Blogs</h1>
        <div
          className='flex flex-row items-center justify-between gap-4 bg-white p-2 border-2 border-blue-500 text-blue-500 rounded-lg shadow cursor-pointer'
          onClick={() => setShowAddProductForm(!showAddProductForm)}
        >
          <h1>Ajouter un Blog</h1>
          <FaPlus />
        </div>
      </div>
      {showAddProductForm && (
        <div className='bg-[#0072a4] p-6 rounded-lg shadow'>
          <h2 className='text-2xl text-white mb-4'>Ajouter un nouveau Blog</h2>
          <form className='flex flex-col justify-center items-center gap-4' onSubmit={handleAddProduct}>
            <input
              type='text'
              name='title'
              placeholder='Nom du blog'
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='text'
              name='description'
      
              placeholder='description'
              
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='file'
              name='img'
              onChange={handleFileChange}
              className='p-2 rounded-lg w-[90%]'
              multiple
            />
            <button className='button bg-white text-[#0072a4] w-1/2 border-white rounded'>Ajouter</button>
          </form>
        </div>
      )}

      {showUpdateProductForm && (
        <div className='bg-[#0072a4] p-6 rounded-lg shadow'>
          <h2 className='text-2xl text-white mb-4'>Modifier un produit</h2>
          <form className='flex flex-col justify-center items-center gap-4' onSubmit={handleUpdateProduct}>
            <input
              type='text'
              name='title'
              placeholder='Nom du blog'
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='text'
              name='description'
              placeholder='description'
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='file'
              name='img'
              onChange={handleFileChange}
              className='p-2 rounded-lg w-[90%]'
              multiple
            />
            <button className='button bg-white text-[#0072a4] w-1/2 border-white rounded'>Modifier</button>
          </form>
        </div>
      )}

      <div className='flex flex-row flex-wrap justify-center gap-4'>
        {products.map(product => (
          <div key={product.idblog} className='flex flex-col justify-center bg-white p-4 w-[30%] border rounded-lg gap-2 shadow'>
            <img src={JSON.parse(product.images)[0]} className='w-full border rounded-lg shadow' alt="" />
            <div className='flex flex-col gap-2'>
              <h1 className='font-bold'>{product.title}</h1>
              <span className='truncate'>{product.description}</span>
              <div className='flex flex-row justify-between items-center'>
                <button className='button border-[1px] text-[#0072a4] border-[#0072a4] rounded-lg p-2' onClick={() => handleEditProduct(product.idblog)}>Modifier</button>
                <MdDeleteOutline className='text-2xl text-blue-500 hover:cursor-pointer' onClick={() => handleDeleteProduct(product.idblog)} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blog;

