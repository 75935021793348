import { Link } from 'react-router-dom'


const Succes = () => {
 
  

  return (
    <div className="flex justify-center items-center min-h-screen bg-green-100">
      <div className="text-center bg-white p-6 border border-blue-200 rounded-md shadow-md">
        <h1 className="text-2xl font-bold text-blue-700 mb-4">Succès</h1>
        <p className="text-blue-700 mb-2">Votre paiement a été envoyé avec succès</p>
        <Link to="/">
          <button className="button mt-4"> 
            Retour à la page d’accueil
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Succes
